<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/customers" />
        </v-col>
      </v-row>

      <customers-fields
        :loading-save="loadingSave"
        :loading-get="loadingGet"
        :loading-delete-contact="loadingDeleteContact"
        :form-validation="formValidation"
        :customer="customer"
        @save="save"
        @delete-contact="deleteContact"
      />
    </v-container>
  </div>
</template>

<script>
  import customersApi from '@/api/customers'

  export default {

    components: {
      CustomersFields: () => import('./CustomersFields'),
    },

    data () {
      return {
        loadingSave: false,
        loadingGet: false,
        loadingDeleteContact: false,
        formValidation: {},
        customer: {},
      }
    },

    mounted () {
      this.get()
    },

    methods: {
      async get () {
        try {
          this.loadingGet = true

          const response = await customersApi.get(this.$route.params.id)

          this.customer = response.data.customer
        } catch (e) {
          this.$router.push('/customers', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      async save (customer) {
        try {
          this.loadingSave = true

          await customersApi.update(customer.id, customer)

          this.$router.push('/customers', () => this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

      async deleteContact (contact) {
        try {
          this.loadingDeleteContact = true

          await customersApi.deleteContact(this.$route.params.id, contact.id)

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })

          await this.loadContacts()
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDeleteContact = false
        }
      },

    },

  }
</script>
